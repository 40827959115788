body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
* Scrollbar rail styles
*/
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}

/*
* Scrollbar thumb styles
*/
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.homePageSlider1 img {
  max-width: 100%;
}
.aboutPage img {
  max-width: 100%;
}
.banner-img img {
  max-width: 100%;
}

.img100 img {
  max-width: 100%;
}

.logo img {
  width: 100px;
}

.zindexne {
  z-index: -1;
}

.invoceCantiner {
  margin-bottom: 150px;
}
.invoceCantiner1 {
  margin-top: 150px;
}

.proRight {
  position: relative;
  z-index: 1;
  min-height: 300px;
}

.mince {
  position: relative;
  z-index: -1;
}

.ProBtn {
  position: absolute;
  bottom: 33px;
}

.thememan{
  background: #000000;
}
.thememan .main-wrap {
  margin-left: 300px;
  background-color: #000000 !important;
  position: relative;
}
.thememan .navbar-aside {
  /* max-width: 300px; */
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  background-color: #000000;
  color: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 999999;
  border-right: 1px solid #eee;
}
.btn {
  height: 36px;
}
.dtpick input.form-control.react-datepicker-ignore-onclickoutside {
  background: #fbfbfb;
}

.custom-radio input {
  border: 1px solid #ececec;
  border-radius: 10px;
  height: 14px;
  -webkit-box-shadow: none;
  /* box-shadow: none; */
  padding-left: 20px;
  font-size: 16px;
  width: 10%;
}

.brand-wrap h6{
font-size: 20px;
color: #000000;
}
.thememan .brand-wrap h6{
font-size: 20px;
color: #ffffff;
}
.thememan .menu-aside .menu-link {
  padding: 10px;
  font-weight: 800;
  font-size: 13px;
  color: #ffffff;
  border-radius: 0.3rem;
  position: relative;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.thememan .menu-aside .menu-link:hover {
 
  color: #000000;
 
}

.thememan .menu-aside .menu-item.active .menu-link {
  background-color:#ffffff ;
  color: #000000;
}
.thememan .main-header {
  padding-left: 3%;
  padding-right: 3%;
  min-height: 72px;
  background-color: rgb(0, 0, 0);
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-bottom: 1px solid #eee;
}
.thememan .content-main {
  padding: 30px 3%;
  margin-left: auto;
  margin-right: auto;
  background-color: #000000;
}
.thememan .card-title {
  color: #fcfcfc !important;
}
.thememan .block-title {
  color: #fcfcfc !important;
}
.thememan .themebtn1 {
  color: #000000;
  border-radius: 17px;
}
.thememan .themebtn1 i {
  color: #ffffff;
  /* border-radius: 10px; */
  background: #000000;
  padding: 5px;
border-radius: 50%;
width: 25px;
height:25px;
}

.dtpick .form-control{
  /* background-color: #f4f5f9; */
  border: 2px solid #5f5f61;
  /* font-size: 13px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 20px;
  color: #4f5d77;
  width: 100%;
  border-radius: 4px;
  height: 41px; */
}
 .themebtn2 {
  color: #ffffff;
  background: #000000;
  border-radius: 17px;
}
 .themebtn2 i{
  color: #000000;
  background: #ffffff;
  padding: 5px;
  border-radius: 50%;
  width: 25px;
  height:25px;
}
.thememan .themebtn1 .fa {
  margin-left: 18px;
}
.form-label {
  font-size: 17px !important;
  color: #000000;
}
.modal-content {
  position: relative;
  /* display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto; */
  background-color: #F3F8FC;
  /* background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0; */
}
.modal-content .card {
 
  background-color: #F3F8FC;

}
#avatar{
  position: relative;
  width: 100px;
}
.proeditbtn {
  position: absolute;
  top: 0;
  right:0;
  background: transparent;
    border: none;

}
 .themebtn2 .fa {
  margin-left: 0px;
  margin-right: 18px;
}
.searchuniversitiesandcourses {
  position: absolute;
  background-color: white;
  left: 0;
  right: 0;
  top: 50px;
  z-index: 99999;
  max-width: 630px;
  border: 2px solid #bce3c9;
  border-top: 1px solid #f2f2f2;
}

.loadingPage {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  /* background-color: #00000087; */
  background-color: white;
}

.tabBtn1 {
  background-color: #eee;
  border: 0;
  border-color: initial;
  border-radius: 4px;
  color: #444;
  font-family: Quicksand, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  margin: 0 4px;
  padding: 10px 8px;
  transition: 0.2s;
}

.tabBtn1.active {
  color: #3bb77e;
  background-color: #fde1bd;
  transform: translateY(-3px);
  /* transition: 0.2s; */
}
.tabBtn1.active {
  color: #3bb77e;
  background-color: #fde1bd;
  transform: translateY(-3px);
  /* transition: 0.2s; */
}
.search-sec {
  /* background-color: #CF9512;
  padding: 0;
  border-radius: 50px;
  border: 0px;
  width: 100%;
  display: inline-flex;
  color: #fff !important;
  width: 280px;
  padding-left: 30px; */
  position: relative;
}

.searchuniversitiesandcourses ul li {
  cursor: default;
  display: block;
  margin-right: 0;
  padding-left: 17px;
  line-height: 35px;
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
  border-bottom: 1px solid #ccc;
}

.bottomLogo img {
  width: 200px;
}

footer .hotline {
  min-width: 300px !important;
}

.searchuniversitiesandcourses ul li:hover {
  /* padding-left: 17px;
    display: block; */
  background: #f2f2f2;
}

button.react-share__ShareButton {
  margin: 10px;
}
.footerBannerImg {
  margin: auto;
}

.footerBannerImg img {
  max-width: 100%;
  margin: auto;
  display: block;
}
.iconSize {
  font-size: 18px !important;
}

.unishareIcon {
  text-align: center;
}

.shareLinkInModel {
  padding: 20px;
}

.searchuniversitiesandcourses ul {
  text-align: start;
}

.homePageSlider {
  position: relative;
}

.homePageSlider .slider-content {
  position: absolute;
  top: 50%;
  left: 6%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.homePageSlider img {
  display: block;
  height: 100%;
  width: auto !important;
  object-fit: cover;
  /* border-radius: 30px; */
}

.homePageSlider .slider-content form {
  background-color: #fff;
  max-width: 450px;
  border-radius: 50px;
}

.homePageSlider .slider-content form input {
  border: 0;
  border-radius: 50px 0 0 50px;
  padding-left: 58px;
  /* background: url("../imgs/theme/icons/icon-plane.png") no-repeat 25px center; */
}

.homePageSlider {
  height: 538px;
  border-radius: 30px;
  background-size: cover;
  background-position: center center;
}

.homePageSlider .slider-content form button {
  border: 0;
  border-radius: 50px;
}

.homePageSlider1 .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #3bb77e;
}

.homePageSlider1 .slick-dots {
  position: absolute;
  bottom: 30px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.homePageSlider1 .slick-dots li button:before {
  font-family: "slick";
  font-size: 17px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sliderM {
  padding-left: 12px;
  padding-right: 12px;
}

.productPage__left {
  position: relative;
}

.productPage__container {
  overflow-x: none;
}

.productPage__container {
  position: relative;
  display: flex;
  width: 100vw;
  font-family: "Montserrat", sans-serif;
  background: #e4e4e4;
}

.productPage__left {
  /* height: 100vh; */
  top: 0px;
  /* width: 60vw; */
  display: flex;
  padding: 20px;
  margin-top: 9vh;
  position: sticky;
  /* background-color: #e4e4e4; */
  text-align: center;
  align-items: center;
}

.productPage__sideImagesContainer {
  height: fit-content;
  background-color: white;
  border: none;
  justify-content: center;
}

.productPage__right {
  width: 55%;
  margin: 10px;
  margin-top: 13vh;
  overflow-y: scroll !important;

  background-color: white;
  margin-right: 39px;
}

.productPage__right::-webkit-scrollbar {
  display: none;
}

.sideImage {
  width: 50px;
  height: 60px;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  border: 2px solid #e4e4e4;
  background-position: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.sideImageActive {
  width: 50px;
  height: 60px;
  cursor: pointer;
  margin: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  /* border: 2px solid #3bb77e; */
  background-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.table {
  overflow: auto;
}

.sideImage img {
  /* margin: auto;
  width: 100%;
  height: 50px;
  display: block; */
  border-radius: 10px;
  max-height: 100%;
  max-width: 100%;
  vertical-align: -webkit-baseline-middle;
  display: inline-block;
}

.sideImage:hover {
  border: none;
  box-shadow: 2px 2px 14px -1px rgba(211, 210, 210, 0.66);
  -webkit-box-shadow: 2px 2px 14px -1px rgba(211, 210, 210, 0.66);
  -moz-box-shadow: 2px 2px 14px -1px rgba(228, 228, 228, 0.66);
}
.sideImageActive img {
  /* margin: auto;
  width: 100%;
  height: 50px;
  display: block; */
  border-radius: 10px;
  max-height: 100%;
  max-width: 100%;
  vertical-align: -webkit-baseline-middle;
  display: inline-block;
}

.sideImageActive:hover {
  border: none;
  box-shadow: 2px 2px 14px -1px rgba(211, 210, 210, 0.66);
  -webkit-box-shadow: 2px 2px 14px -1px rgba(211, 210, 210, 0.66);
  -moz-box-shadow: 2px 2px 14px -1px rgba(228, 228, 228, 0.66);
}

.productPage__displayImageContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 101.5%;
  background-color: white;
  margin-left: 18px;
  border-radius: 20px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.product_page_top {
  background-color: #e4e4e4;
}

.productPage__displayImage {
  max-width: 80%;
  height: 80%;
  /* max-height: 400px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* .imgViewS img:first-child{
  max-height: 400px;
    max-width: 100%;
    width: auto !important;
    margin: auto;
} */
.imgViewS1 {
  max-height: 400px;
  max-width: 100%;
  width: auto !important;
  margin: auto;
}

child {
  border: 2px solid orange;
}

.imgViewS {
  color: red;
  background-color: #fff;
}

.tabBtn {
  background-color: #eeeeee;
  font-size: 15px;
  margin: 0 10px;
  margin-left: 10px;
  color: #444;
  border-radius: 4px;
  padding: 15px 24px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border: 0;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  line-height: 1;
}
.tabBtn:hover {
  color: #3bb77e;
  background-color: #fde1bd;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-transition: 0.2s;
  transition: 0.2s;
  /* padding: 15px; */
  /* border: none; */
}
.tabBtn.active {
  color: #3bb77e;
  background-color: #fde1bd;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-transition: 0.2s;
  transition: 0.2s;
  /* padding: 15px;
  border: none; */
}

.menubtn {
  display: inline-block;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 20px;
  border-radius: 4px;
  color: #fff;
  border: 1px solid transparent;
  background-color: transparent !important;
  cursor: pointer;
  -webkit-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  letter-spacing: 0.5px;
}
.card-header1 {
  padding: 1.3rem 1.3rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(222, 226, 230, 0.7);
}

.dataTables_paginate.paging_simple_numbers.category {
  float: left;
}

.dataTables_wrapper .dataTables_paginate {
  padding: 10px 0;
}

.dataTables_paginate.paging_simple_numbers.category .previous a {
  /* color: #40189D; */
  color: var(--bs-pagination-color) !important;
}
.dataTables_paginate.paging_simple_numbers.category .next a {
  /* color: #40189D; */
  color: var(--bs-pagination-color) !important;
}
.dataTables_paginate.paging_simple_numbers.category li:nth-child(1) {
  background: #e9ecee !important;
  color: var(--bs-pagination-color) !important;
}

.dataTables_paginate.paging_simple_numbers.category li {
  border: 0 !important;
  padding: 7px 16px;
  border: 0px solid #40189d !important;
  background: #e9ecee !important;
  border-radius: 0.5rem;

  color: var(--bs-pagination-color) !important;
  font-size: 16px;
  margin: 3px;
  display: inline-block;
}

/* .dataTables_paginate.paging_simple_numbers.category li:nth-child(1){

  background: transparent !important;
  

} */
.dataTables_paginate.paging_simple_numbers.category li:last-child {
  background: #e9ecee !important;
  color: var(--bs-pagination-color) !important;
}
.fw-semibold .text-gray-600 tr td {
  color: var(--bs-pagination-color) !important;
}

.next.disabled {
  pointer-events: none;
}
.previous.disabled {
  pointer-events: none;
}

.dataTables_paginate.paging_simple_numbers.category li.selected {
  color: #fff !important;
  background: #3bb77e !important;
}

.dataTables_paginate.paging_simple_numbers.category li:hover {
  background: #3bb77e !important;
}

.image-item {
  display: flex;
  margin: 10px 0;
}

.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.coustomSelect {
  height: 35px;
}
.coustomSelect .coustomSelect__input {
  height: 35px;
  color: #000000 !important;
}

.coustomSelect__input-container {
  color: #000000 !important;
}
.coustomSelect .css-qbdosj-Input {
  visibility: visible;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1/1/2/3;
  /* grid-template-columns: 0 min-content; */
  /* margin: 2px; */
  /* padding-bottom: 2px; */
  /* padding-top: 2px; */
  color: #000000;
  box-sizing: border-box;
  height: 35px;
}

.btn-light {
  color: #000000;
  margin-left: 6px;
}

.homePop img {
  width: 100%;
}

.changeEmail {
  margin-top: 30px;
}
.single-thumbnail img {
  border-radius: 15px;
  overflow: hidden;
  max-width: 100%;
}
.bolgBox img {
  max-width: 100%;
}

.post-thumb img {
  max-width: 100%;
}
.minHeCate {
  height: 350px;
  overflow: auto;
}

.coustomP {
  padding: 6px 10px !important;
}

.redBtn {
  background-color: red;
}
.activeCate {
  border: 1px solid #bce3c9 !important;
  -webkit-box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
  transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
}

@media only screen and (max-width: 1024px) {
  .homePageSlider {
    height: 350px;
  }
}

@media only screen and (max-width: 1400px) {
  .homePageSlider {
    height: 467px;
  }
}

@media only screen and (max-width: 992px) {
  .productPage__container {
    flex-direction: column;
  }
  .productPage__left {
    width: 97vw;
  }
  .detail-info h1 {
    font-size: 25px;
  }
  .homePageSlider {
    height: 240px;
  }
  .product-info {
    border: 0;
    padding: 0;
    overflow: auto;
  }
}
@media only screen and (max-width: 768px) {
  .productPage__container {
    flex-direction: column;
  }
  .productPage__left {
    width: 97vw;
  }
  .detail-info h1 {
    font-size: 25px;
  }
  .homePageSlider {
    height: 180px;
  }
  .product-info {
    border: 0;
    padding: 0;
    overflow: auto;
  }
  #avatar {
    text-align: center;
  }
}
@media only screen and (max-width: 480px) {
  .hero-slider-1 .single-hero-slider .display-2 {
    font-size: 32px;
  }
  .detail-info h1 {
    font-size: 21px;
  }
  .product-info {
    border: 0;
    padding: 0;
    overflow: auto;
  }
  .homePageSlider {
    height: 190px;
  }
  .homePageSlider img {
    display: block;
    height: 100%;
    width: auto !important;
    object-fit: cover;
    border-radius: 1px;
}
#avatar {
  text-align: center;
}
.popular-categories h1 {
  font-size: 30px;
}
}



.dropButton {
  color: black !important;
  background-color: #ffffff !important;
  padding: 10px 10px !important;
}

.dropButton.dropdown-toggle:after {
  position: absolute;
  top: 50%;
  color: #0c0c0c;
  right: 0px;
}

.modalBoXContaint {
  width: 100%;
  overflow: auto;
  margin-bottom: 80px;
}

.sticky-bar.stick {
  -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 8px 20px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 5%);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-bottom: 2px solid #ff9696;
  background: #fff;
}

.faq-section {
  background: #fdfdfd;
  min-height: 100vh;
  padding: 10vh 0 0;
}
.faq-title h2 {
  position: relative;
  margin-bottom: 45px;
  display: inline-block;
  font-weight: 600;
  line-height: 1;
}
.faq-title h2::before {
  content: "";
  position: absolute;
  left: 50%;
  width: 60px;
  height: 2px;
  background: #e91e63;
  bottom: -25px;
  margin-left: -30px;
}
.faq-title p {
  padding: 0 190px;
  margin-bottom: 10px;
}

.faq {
  background: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.faq .card {
  border: none;
  background: none;
  border-bottom: 1px dashed #cee1f8;
}

.faq .card .card-header {
  padding: 0px;
  border: none;
  background: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.faq .card .card-header:hover {
  background: rgba(233, 30, 99, 0.1);
  padding-left: 10px;
}
.faq .card .card-header .faq-title {
  width: 100%;
  text-align: left;
  padding: 0px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 1px;
  color: #3b566e;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
}

.faq .card .card-header .faq-title .badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 14px;
  float: left;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  text-align: center;
  background: #e91e63;
  color: #fff;
  font-size: 12px;
  margin-right: 20px;
}

.faq .card .card-body {
  padding: 30px;
  padding-left: 35px;
  padding-bottom: 16px;
  font-weight: 400;
  font-size: 16px;
  color: #6f8ba4;
  line-height: 28px;
  letter-spacing: 1px;
  border-top: 1px solid #f3f8ff;
}

.faq .card .card-body p {
  margin-bottom: 14px;
}

.searchDesplay {
  display: none;
}
.puan p span{
  text-transform: uppercase;
  color: #000000;
}
.contecttest span{
  color: #000000;
}
.mtrp a {
  color: #000000;
}
.pt .ram .nav-tabs .nav-link {
  color: #000000;
  font-weight: 700;
}
.puan p strong{
 font-weight: 700;
 color: #000000;
}
.mtrp li:nth-child(odd){
  background: #dadada;
}
.mtrp li{
 padding: 5px;
}
@media (max-width: 991px) {
  .faq {
    margin-bottom: 30px;
  }
  .searchDesplay {
    display: block !important;
    width: 100%;
    position: absolute;
    top: 45px;
    background: #fff;
    padding: 10px 10px;
    z-index: 9;
  }
  .faq .card .card-header .faq-title {
    line-height: 26px;
    margin-top: 10px;
  }

  .product-info {
    border: 0;
    padding: 0;
    overflow: auto;
  }
}

.moredate {
  padding: 10px !important;
}

.itemside .left img {
  height: 60px !important;
}

.bticon i {
  padding-right: 15px;
}
/* .main-menu > nav > ul > li ul.mega-menu {
  width: auto !important;
} */
.main-menu > nav > ul > li ul.mega-menu li.sub-mega-menu-width-22 {
  min-width: 250px;
}

.sideNav-overlay {
  background: rgba(0, 0, 0, 0.57);
  height: 100%;
  left: 0;
  opacity: 1;
  pointer-events: auto;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.25s cubic-bezier(0, 0, 0.3, 1);
  width: 100%;
  z-index: 9;
}

.cateheading {
  font-size: 20px;
}

.btn-success2 {
  color: #fff;
  background-color: #198754 !important;
  border-color: #198754 !important;
  margin-left: 5px;
}

.verificationRed {
  text-align: center;
  justify-content: center;
}

.verificationRed p {
  color: red;
  font-size: 16px;
}

.package {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.package__item {
  height: 100%;
  transition: all ease-in-out 0.3s;
}
/* .package__item:nth-child(1) .package__header {
  background-color: #eea726;
} */

/* .package__item:nth-child(2) .package__header {
  background-color: #459bba;
} */
/* .package__item .package__header {
  background-color: #459bba;
} */
.package__item .package__header .package__name {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  padding: 8px;
  color: white;
}

/* .package__item:nth-child(3) .package__header {
  background-color: #78a87f;
} */

.package__item .package__body {
  background-color: white;
  padding: 1rem;
  min-height: 82%;
}

.package__item .package__body .package__price-container {
  min-height: 150px;
  background-color: rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
}

.package__item .package__body .package__price {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  position: relative;
}

.package__item .package__body ul {
  margin-top: 1rem;
}

.package__item .package__body ul li {
  position: relative;
  font-size: 0.875rem;
  font-weight: 600;
  color: #31363c;
}

.package__item .package__header {
  background-color: #ff8300;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}

.package__item .package__body .package__price-container--column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}

.package__item
  .package__body
  .package__price-container--column
  .package__column {
  display: grid;
}

.package__item
  .package__body
  .package__price-container--column
  .package__column--2 {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}
.package__item
  .package__body
  .package__price-container--column
  .package__column--2
  .package__column-title {
  background-color: #5d9064;
}

.package__item
  .package__body
  .package__price-container--column
  .package__column
  .package__column-title {
  color: white;
  background-color: #78a87f;
  font-weight: 600;
}

.package__item
  .package__body
  .package__price-container--column
  .package__column
  [class^="package__column"] {
  display: flex;
  align-items: center;
  justify-content: center;
}

.package__item
  .package__body
  .package__price-container--column
  .package__column
  .package__column-price--bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.package__item .package__footer button {
  background-color: #ff8300;
  color: white;
  padding: 8px 0;
  width: 100%;
  border: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: all ease-in-out 0.3s;
  font-weight: 600;
}

.package__item .active button {
  background-color: #78a87f;
}

/* .package__item:nth-child(3) .package__footer button:hover {
  background-color: #659c6d;
}

.package__item:nth-child(3) .package__footer button {
  background-color: #78a87f;
} */

.ril_id_box {
  width: 500px;
  position: relative;
}

.ril_di_card {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 100;
}

.ril_di_card_left {
  width: 50%;
  position: relative;
}

.ril_di_card_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 50%;
  font-weight: 100;
  font-family: poins;
  font-size: 21px;
  /* color: #fe5928; */
}

.ril_img {
  position: absolute;
  width: 117px;
  height: 117px;
  border-radius: 50%;
  right: 5px;
  bottom: -55px;
}

.ril_logo {
  position: absolute;
  top: 0;
  right: 0;
}

.watermark {
  opacity: 0.2;
  /* color: BLACK; */
  position: absolute;
  top: auto;
  right: 0;
  /* font-size: 65px; */
  transform: rotate(45deg);
}

.ril_di_card_top {
  position: absolute;
  top: 0;
  text-transform: capitalize;
  font-size: 31px;
  color: #ff5829;
}

.rilIDText {
  font-size: 20px;
  font-weight: 600;
  text-align: justify;
  padding: 38px;
}

.information-title {
  border-bottom: 3px solid #7d7d7d;
  background-color: #7d7d7d;
  color: #ffffff;
}

.information-title {
  padding: 10px 20px;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

.recent-post .media-category {
  color: #000000;
}
.recent-post .media-heading a:hover {
  color: #000000;
}
.widget .recent-post .media-meta a:hover {
  color: #000000;
}

.media-object {
  display: block;
}

.recent-post .media-body {
  font-size: 16px;
  line-height: 18px;
}

.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top;
}

.media-body {
  width: 10000px;
}

.media,
.media-body {
  overflow: hidden;
  zoom: 1;
}

.profile_ulv {
  padding: 9px;
  background-color: #fff;
  /* border-radius: 10px; */
  border: 1px solid #ddd;
  box-shadow: 0px 0px 22px 0px #dcdcdc;
  margin-bottom: 2px;
  /* margin-right: 10px; */
  height: 68px;
}
.profile_ul {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 22px 0px #dcdcdc;
  margin-bottom: 10px;
}

.selectedSize {
  border: 1px solid rgb(0, 0, 0);
  text-align: center;
  background: #000000;
  color: #fff;
  border-radius: 15px;
}
.unselectedSize {
  border: 1px solid rgb(123, 123, 123);
  text-align: center;
  border-radius: 15px;
}

.pDetails .product-price {
  padding-top: 5px;
}

.pDetails .product-price span {
  font-size: 18px;
  font-weight: bold;
  color: #0D6EFD;
}
.pDetails .product-price span.old-price {
  font-size: 14px;
  color: #adadad;
  margin: 0 0 0 7px;
  text-decoration: line-through;
}

.react-datepicker__month-container {
  position: relative;
  z-index: 999999;
}
.text-green-700{
  color: #146C43;
  font-weight: 700;
}
.text-red-700{
  color: #0D6EFD;
  font-weight: 700;
}

.form-group select {
  background: #fff;
  border: 1px solid #ececec;
  height: 64px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 20px;
  font-size: 16px;
  width: 100%;
}

.registersuccessBox{
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 5px;
  max-width: 350px;
  background-color: #fed4cb;
  /* min-height: 230px; */
}
.registersuccessBox h5{
  color: #088d4f;
  font-size: 15px;
}
.registersuccessBox h6{
  /* color: #088d4f; */
  font-size: 12px;
}

.card-body{
  overflow: auto;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stars {
  display: flex;
}

.star {
  font-size: 24px;
  margin: 0.2rem;
}

.achieved {
  color: yellow;
}

.unachieved {
  color: gray;
}

.menu-aside{
  height: 80vh;
  overflow: auto;
}


/* range */
.range{
  border: 2px dotted black;
  outline: 5px solid rgb(76, 125, 158);
  position: relative;
  background: rgb(76, 125, 158);
}
.range_star{

  margin-top: -25px;
  position: relative;
  z-index: 9;
}
td{
  color: #000000;
}
.range_star img{
  height: 35px;
  width: 35px;
}

.profile_ul1 {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 22px 0px #dcdcdc;
}
.range_star img:hover{
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
}
.star_p{
  font-size:12px;
}

.logomaImg1{
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.logoma {
  width: 110px;
  margin: auto;
  position: relative;
}
.logomaImg {
  position: absolute;
  top: 17px;
  left: 21px;
  width: 69px !important;
  height: 73px;
}
.logoma img{
  width: 100%;
  
}
.logoman {
  width: 70px;
}

.logoman img{
  width: 30%;
  position: absolute;
  margin-left: -15%;
  margin-top: 5%;
}
.badge{
  z-index: 1;
  position: relative;
  margin-top: 20px;
}
.name_box {
  border: 1px solid red;
  padding: 3px 34px 3px 40px;
  background-color: #fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}

.topSellerRankDiv {
  position: absolute;
  top: -18px;
  display: flex;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.TopsellerRank {
  display: flex;
  height: 35px;
  background-color: #f39a56;
  border-radius: 50%;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  width: 35px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  border: 3px solid #fff;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0px 4px 9px rgba(255, 170, 46, 0.4);
}
.crown::before {
  margin-right: 0px;
  content: '';
  background: url("./taj.png");
  position: absolute;
  background-repeat: no-repeat;
  top: -16px;
  width: 26px;
  height: 24px;
  background-size: contain;
}

.prodetailsbox{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
  border-radius: 10px;
  margin-top: 15px;
}
.prodetailsbox1{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 
}
.prodetailsbox h1{
 font-size: 20px;
}
.newfaq .badge{
  margin-top: 2px;
}

.licebox {
  background: #ffffff;
  padding: 20px;
}
.liceboxnew {
  background: #ffffff;
  padding: 15px;
  box-shadow: 0px 0px 22px 0px #dcdcdc;
  border-radius: 5px;
}

.liceninput{
  height: 30px;
}
.liceninput input{
  height: 30px;
}

.lcbtn {
  padding: 5px !important;
}

.chrtitmess {
  position: relative;
}
.chrtitmess .pro-count.blue {
  background-color: #0D6EFD;
  position: absolute;
  right: -11px;
  top: -5px;
  color: #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}
.fa {
  margin-left: 5px;
}

.btncccc{
  color: #198754 !important;
}

.oncartl {
  border: 4px solid white;
  border-radius: 35px;
  box-shadow: 0px 6px 15px 1px pink;
  height: 108px;
}

.float-right{
  float: right;
}

.rui.search-modal-header {
  padding-top: 10px !important;
}
.rui.search-modal-header {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  background: #ffffff;
  position: fixed;
  top: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: background-color 200ms linear;
}
.rui.search-modal-header {
  z-index: 150;
}


.rui.search-modal-input {
  width: 96%;
  position: relative;
  border-bottom: 1px solid #999999;
}

.rui.search-modal-input .search-submit {
  right: 10px;
  font-size: 12px;
  position: absolute;
  top: 42px;
  color: #666666 !important;
  cursor: pointer;
  background: #e6e6e6;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 20px !important;
  border-style: none !important;
  transition: background-color 200ms linear;
}

.rui.search-modal-close {
  right: 10px;
}
.rui.search-modal-close {
  position: absolute;
  top: 10px;
  z-index: 1051;
}
.search-modal .rui.btn-default.icon-only {
  position: fixed;
  right: 10px;
}
.rui.btn-default.icon-only {
  color: #505558;
  background-color: #ffffff;
  border-color: #ffffff;
}
.rui.btn.icon-only {
  border: none;
  font-size: 10px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  color: #505558;
  background-color: #ffffff;
  box-shadow: none;
}

.buttons{
  border-radius: 50px;
  border: 1px solid green;
  background-color: navajowhite;
  width: 80px;
  height: 40px;

}  
.button3{
  border-radius: 50px;
  border: 1px solid green;
  background-color: white;
  width: 130px;
  height: 40px;

}

.prdehr{
  color: orange;
  height: 1px !important;
  opacity: 1;
}

button.addcrtbtn.btn.btn-success {
  width: 100%;
  background: #0D6EFD !important;
  border-radius: 35px;
  box-shadow: 0px 6px 20px 0px #ff952854;
}

.checkoutbx{
  background: #ffd9b3;
}

.col-lg-1-5.primary-sidebar.sticky-sidebar input{
  width: auto;
}

.wrap-collabsible-filt {
  padding: 2px 10px 2px 10px;
  text-align: center;
  float: left;
  position: relative;
  background-color: #FD724D;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 7px;
}
.lbl-toggle-filt {
  font-weight: 500;
  font-family: 'Titillium Web', sans-serif;
  font-size: 12px;
  text-align: left;
  color: #000;
  cursor: pointer;
  transition: all 0.25s ease-out;
}

.lbl-toggle-filt::after {
  content: '+';
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.7rem;
  transition: transform 0.2s ease-out;
  transform: rotate(45deg);
  margin-top: -3px;
}

.filter-head {
  margin-top: 20px;
  font-family: sans-serif;
}

.filter-head {
  font-size: 14px;
  color: #717171;
  display: inline-block;
  font-weight: 500 !important;
}
.vertical-div {
  width: 10%;
  display: inline-block;
  margin: 2px 20px 5px 20px !important;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: black;
}

.filter-head-clr {
  color: #e2785a;
  cursor: pointer;
}

.blogtitlt{
  font-size: 14px;
}

form.hin {
 
  float: inline-end;
}

/* Header Start */

 /* Media Query */
 /* * body{
	background-color: antiquewhite;
} */
@media only screen and (max-width: 575px) {
 .our{
    	display: none;
    	
  }
  .per{
  	margin-top: 110px;
  }
  
}


.abpage .per{
  /* font-family: Lucida Console; */
  font-weight: lighter;
  font-size: 28px;
  line-height: 39px;
  color: dimgray;
}
.per-1{
	    font-size: 42px;
}
.on{
	border: 2px solid green;
}





  

*{
	margin:0;
	box-sizing: border-box;
}
:before,:after{
	box-sizing: border-box;
}
.container{
	max-width: 1200px;
	margin:auto;
}
.row{
	display: flex;
	flex-wrap: wrap;
}
.v-center{
	align-items: center;
}
ul{
	list-style: none;
	margin:0;
	padding:0;
}
a{
	text-decoration: none;
}
/* header */
.header{
	display: block;
	width: 100%;
	position: relative;
	z-index: 99;
	padding:15px;
}
.header .item-left{
	flex:0 0 17%;
}
.header .logo a{
	font-size: 30px;
	color:#000000;
	font-weight: 700;
	text-decoration: none;
}
.header .item-center{
	flex:0 0 66%;
}
.header .item-right{
	flex:0 0 17%;
	display: flex;
	justify-content: flex-end;
}
.header .item-right a{ 
     text-decoration: none;
     font-size: 16px;
     color:#555555;
     display: inline-block;
     margin-left: 10px;
     transition: color 0.3s ease;
}
.header .menu > ul > li{
	display: inline-block;
	line-height: 50px;
	margin-left: 25px;
}
.header .menu > ul > li > a{
	font-size: 15px;
	font-weight: 500;
	color:#000000;
	position: relative;
	text-transform: capitalize;
	transition: color 0.3s ease;
  padding-bottom: 5px;
}
.header .menu > ul > li > a:hover{
  border-bottom: 2px solid red;

}

.profile_ul .card-body {
  overflow:hidden
}
.header .menu > ul > li .sub-menu{
	position: absolute;
	z-index: 500;
	background-color:#ffffff;
	box-shadow: -2px 2px 70px -25px rgba(0,0,0,0.3); 
	padding: 20px 30px;
	transition: all 0.5s ease;
	margin-top:25px;
	opacity:0;
	visibility: hidden;
}
@media(min-width: 992px){
.header .menu > ul > li.menu-item-has-children:hover .sub-menu{
	margin-top: 0;
	visibility: visible;
	opacity: 1;
}
}
.header .menu > ul > li .sub-menu > ul > li{
	line-height: 1;
}
.header .menu > ul > li .sub-menu > ul > li > a{
	display: inline-block;
	padding: 10px 0;
	font-size: 15px;
	color: #555555;
	transition: color 0.3s ease;
	text-decoration: none;
	text-transform: capitalize;
}
.header .menu > ul > li .single-column-menu{
	min-width: 280px;
	max-width: 350px;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li{
   line-height: 1;
   display: block; 
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
	padding:10px 0;
	display: inline-block;
	font-size: 15px;
	color:#555555;
	transition: color 0.3s ease;
}
.header .menu > ul > li .sub-menu.mega-menu{ 
    left: 50%;
    transform: translateX(-50%);	
}

.header .menu > ul > li .sub-menu.mega-menu-column-4{
  max-width: 1100px;
  width: 100%; 	
  display: flex;
  flex-wrap: wrap;
  padding:20px 15px;
  /* flex-direction: column; */
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item{
  flex:0 0 25%;
  padding:0 15px;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4   .list-item .title{
  font-size: 13px;
  color: #000000;
  font-weight: 500;
  /* line-height: 0.8; */
  font-weight: 700;
  padding: 1px 0;
  margin-bottom: 0px;
  padding: 5px;
}

.sub-menu.mega-menu.mega-menu-column-4 h5{
  margin-bottom: 10px;
}
.sub-menu.mega-menu.mega-menu-column-4 .subsubbg h5{
  margin-bottom: 10px;
  padding-left: 10px;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center .title{
	text-align: center;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img{
	max-width: 100%;
	width: 100%;
	vertical-align: middle;
	margin-top: 10px;
	height: 300px;
	object-fit: cover;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a:hover,
.header .menu > ul > li .sub-menu > ul > li > a:hover,
.header .item-right a:hover,
.header .menu > ul > li:hover > a{
	color:#ea4636;
}

.subsubbg {
  /* min-height: 266px !important; */
  /* background-color: #7E9BC6; */
  background-color: #f1f6fd;
}
.subcatactive {
  /* min-height: 266px !important; */
  background-color: #f1f6fd;
}
.subsubbg li {
  line-height: 1.7;
  margin: 0px !important;
  padding: 1px !important;
  padding-left: 15px !important;
}
.subsubbg li a{
 color: #000;
}
/* banner section */
/* .banner-section{
  background-image: url('../img/banner.jpg');
  background-size: cover;
  background-position: center;
  height: 700px;
  width: 100%;
  display: block;
} */
.mobile-menu-head,
.mobile-menu-trigger{
	display: none;
}

/*responsive*/
@media(max-width: 991px){

	.header .item-center{
		order:3;
		flex:0 0 100%;
	}
	/* .header .item-left,
	.header .item-right{
		flex:0 0 auto;
	} */
	.v-center{
		justify-content: space-between;
	}
	.header .mobile-menu-trigger{
		display: flex;
		height: 30px;
		width: 30px;
		margin-left: 15px;
		cursor: pointer;
		align-items: center;
		justify-content: center;
	}
	.header .mobile-menu-trigger span{
		display: block;
		height: 2px;
		background-color: #333333;
		width: 24px;
		position: relative;
	}
	.header .mobile-menu-trigger span:before,
	.header .mobile-menu-trigger span:after{
		content: '';
		position: absolute;
		left:0;
		width: 100%;
		height: 100%;
		background-color: #333333;
	}
	.header .mobile-menu-trigger span:before{
		top:-6px;
	}
	.header .mobile-menu-trigger span:after{
		top:6px;
	}
	.header .item-right{
		align-items: center;
	}

	.header .menu{
		position: fixed;
		width: 320px;
		background-color:#ffffff;
		left:0;
		top:0;
		height: 100%;
		overflow: hidden;
		transform: translate(-100%);
		transition: all 0.5s ease;
		z-index: 1099;
	}
	.header .menu.active{
	   transform: translate(0%);	
	}
	.header .menu > ul > li{
		line-height: 1;
		margin:0;
		display: block;
	}
	.header .menu > ul > li > a{
		line-height: 50px;
		height: 50px;
		padding:0 50px 0 15px;
		display: block;
		border-bottom: 1px solid rgba(0,0,0,0.1);
	}
	.header .menu > ul > li > a i{
		position: absolute;
		height: 50px;
		width: 50px;
		top:0;
		right: 0;
		text-align: center;
		line-height: 50px;
		transform: rotate(-90deg);
	}
	.header .menu .mobile-menu-head{
		display: flex;
		height: 50px;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 501;
		position: sticky;
		background-color: #ffffff;
		top:0;
	}
	.header .menu .mobile-menu-head .go-back{
		height: 50px;
		width: 50px;
		border-right: 1px solid rgba(0,0,0,0.1);
		cursor: pointer;
		line-height: 50px;
		text-align: center;
		color:#000000;
		font-size: 16px;
		display: none;
	}
	.header .menu .mobile-menu-head.active .go-back{
		display: block;
	}
	.header .menu .mobile-menu-head .current-menu-title{
		font-size: 15px;
		font-weight: 500;
		color:#000000;
	}
	.header .menu .mobile-menu-head .mobile-menu-close{
	    height: 50px;
		width: 50px;
		border-left: 1px solid rgba(0,0,0,0.1);
		cursor: pointer;
		line-height: 50px;
		text-align: center;
		color:#000000;	
		font-size: 25px;
	}
	.header .menu .menu-main{
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}
	.header .menu > ul > li .sub-menu.mega-menu,
	.header .menu > ul > li .sub-menu{
		visibility: visible;
		opacity: 1;
		position: absolute;
		box-shadow: none;
		margin:0;
		padding:15px;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		padding-top: 65px;
		max-width: none;
		min-width: auto;
		display: none;
		transform: translateX(0%);
		overflow-y: auto;
	}
.header .menu > ul > li .sub-menu.active{
	display: block;
}
@keyframes slideLeft{
	0%{
		opacity:0;
		transform: translateX(100%);
	}
	100%{
	    opacity:1;
		transform: translateX(0%);	
	}
}
@keyframes slideRight{
	0%{
		opacity:1;
		transform: translateX(0%);
	}
	100%{
	    opacity:0;
		transform: translateX(100%);	
	}
}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img{
		margin-top:0;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center .title{
		margin-bottom: 20px;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center:last-child .title{
		margin-bottom:0px;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item{
		flex: 0 0 100%;
        padding: 0px;
	}
	.header .menu > ul > li .sub-menu > ul > li > a,
	.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a{
		display: block;
	}
	.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul {
		margin-bottom: 15px;
	}
	.menu-overlay{
		position: fixed;
		background-color: rgba(0,0,0,0.5);
		left:0;
		top:0;
		width: 100%;
		height: 100%;
		z-index: 1098;
		visibility: hidden;
		opacity:0;
		transition: all 0.5s ease;
	}
	.menu-overlay.active{
	  visibility: visible;
	  opacity:1;	
	}
}
.header{
	background-color: #ededed;
	/* background-color: #c8dcff; */
}

table tr {
  /* padding: 10px 20px;
  border: 1px solid #ececec; */
  vertical-align: middle;
  text-align: center;
}


.first1{
					margin-top: -66px;
}
.our{
	background-color: #e6ffe6;
	font-weight: 550;
	font-size: 23px;
	color: #4b339b;
	font-style: italic;
}

.faq-title2{
  background-color: #fdf4eb;
}

.pic{
	background-image: url('https://cdn.pixabay.com/photo/2017/09/15/19/41/businessman-2753324_960_720.jpg');
	background-repeat: no-repeat;
	width: 100%;
	height:550px;
	background-size: cover;
}
.abpage .pic{
	background-image: url('AboutUs1.png');
	background-repeat: no-repeat;
	width: 100%;
	height:550px;
	background-size: cover;
}

.pic{
	background-image: url('Compliance.png');
	background-repeat: no-repeat;
	width: 100%;
	height:550px;
	background-size: cover;
}
@media only screen and (max-width: 575px) {
  .abpage .pic{
    background-image: url('AboutUs1.png');
    background-repeat: no-repeat;
    width: 100%;
    height:175px;
    background-size: cover;
  }
  
  .pic{
    background-image: url('Compliance.png');
    background-repeat: no-repeat;
    width: 100%;
    height:160px;
    background-size: cover;
  }

  .fiv1 {
    font-style: italic;
    color: #684b8f;
    font-size: 14px;
}

.page-header h1{
  font-size: 20px;
}
   
 }
.compliance .cet{
	font-family: Verdana;
	line-height: 25px;
	color: #23566D;
	font-size: 14px;
	cursor: pointer;
}
.compliance i{
	color: sandybrown;
}

.compliance .toy{
	border: 1px solid gray;
}

.compliance .our {
  background-color: antiquewhite;
  width: 600px;
  font-family: arial;
  font-size: 20px;
  font-weight: 500;
  margin-top: -80px;
}

.mheader {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.menu-aside .fa{
  font-size: 15px;
  margin-right: 10px;
}

.row.product-grid .product-grid {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}



/* user deshboard */


.card1 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
  height: 10rem;

  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card1 {
  border-left: 20px solid rgb(24, 130, 178);
  border-radius: 30px;

}

.card2 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
  height: 10rem;

  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card2 {
  border-left: 20px solid rgb(69, 178, 150);
  border-radius: 30px;

}

.card3 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
  height: 10rem;

  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card3 {
  border-left: 20px solid rgb(250, 149, 50);
  border-radius: 30px;

}

.butt {
  padding: 0px 10px;
  margin-right: 10px;
  border: 1px solid #afadad;
  border-radius: 15px;
  font-size: 10px;
  cursor: pointer;
  color: #afadad !important;
  margin-left: 55px;
}



.card4 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
   height: 150px;
  float: left;
  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card4 {
  border-left: 20px solid rgb(184, 135, 229);
  border-radius: 30px;

}

.buuton {
  margin-left: 800px;
  border-color: blue;
  background-color: white;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;

}


.card5 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
  height: 150px;
  float: left;
  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card5 {
  border-left: 20px solid green;
  border-radius: 30px;

}


.img1 {
  position: absolute;
  width: 80px;
  margin-left: 80px;
  margin-top: -50px;
}

.i1 {
  margin-left: 190px;
}


.img2 {
  position: absolute;
  width: 80px;
  margin-left: 55px;
  margin-top: -50px;
}

.img3 {
  position: absolute;
  width: 80px;
  margin-left: 175px;
  margin-top: -50px;
}

.businessRecord th {
  padding: 10px 8px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}

.ant-card-wider-padding .ant-card-body {
  padding: 24px;
}

.businessRecord td {
  padding: 10px 0px;
  font-weight: bold;
}
td{
  text-align: center;
}

/* prfile page  */

#puan {
  width: 100%;
  padding: 5px;
}

.pt .puan {
  background: rgb(255, 255, 255);
  box-shadow: 0px 5.12253px 25.6126px rgba(255, 149, 40, 0.25);
  padding: 5px;
  border-radius: 35px;
}


.pt .puan #avatar img {
  width: 80px;
  height: 80px;
  border-radius: 215px;
}

.pt .puan #name {
  font-family: Roboto, Noto, Helvetica, Arial, sans-serif;
  color: #fff;
  font-size: 18px;
  position: absolute;
  margin: -91px 112px;
}

.pt .puan #whatpuan {
  font-family: Roboto, Noto, Helvetica, Arial, sans-serif;
  color: rgb(244, 222, 54);
  font-size: 18px;
  position: absolute;
  margin: -65px 112px;
}

.pt .puan #desc {
  font-family: Roboto, Noto, Helvetica, Arial, sans-serif;
  color: #fff;
  font-size: 14px;
  position: absolute;
  margin: -41px 112px;
}

.pt  .ranktitle {
  background-color: #ccc;
  font-size: 8px;
  height: 55px;
  width: 55px;
  padding: 20px 5px;
  border-radius: 30px;
}

.pt  .button1 {
  color: rgb(24, 144, 255);
  background-color: rgb(186, 231, 255);
  border: 1px solid rgb(186, 231, 255);
  border-radius: 30px;
}

.pt h6 {
  font-size: 14px;
}

.pt .rounded-circle {

  height: 90px;
  width: 80px;
  border: 6px solid rgb(135, 208, 104);
  border: none !important;
  padding: 20px 15px;
  color: rgb(135, 208, 104);
  ;

}
.nav-item img.rounded-circle {
  border: none !important;
}
.pt .select {
  width: 200px;
  border-radius: 30px;
  height: 38px;
}

.pt .bor {
  border-right: 2px solid;
}

.pt .ram {
  border-bottom: 2px solid;
  position: relative;
}
.pt .bot1{
  border: 1px solid #eee;
  background-color: #eee;
  margin-left: 10px;
  border-radius: 20px;
  font-size: 14px;
  color: #ccc;
  
  
  }
  .pt .d-flex{
  float: right;
}
.pt .bot2{
       background-color: transparent;
       border:1px solid rgb(204, 204, 204);
       color: rgb(204, 204, 204);
       border-radius: 20px;
       height: 35px;
       width: 70px;
}
.pt .image{
  height: 250px;
  width: 250px;
}
.pt .save {
  background-color: #00c2ff;
  border: 1px solid rgb(204, 204, 204);
  color: rgb(31 31 31);
  border-radius: 20px;
  height: 35px;
  width: 140px;
  margin-left: 35%;
  font-size: 15px;
  font-weight: 700;
}


.pt .pixDiv {
  border: 2px;
  width: 138px;
  height: 178px;
  margin: 0 auto 30px;
  display: block;
  position: relative;
  justify-content: flex-end;
  flex-direction: column;
  border-style: dashed;
  border-radius: 20px;
  border-color: #c4c4c4;
  box-shadow: 0px 10px 20px 0px #c4c4c4;
}

.pt .pixDiv .pixDocumentTitle {
  border-top: 2px dashed #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  color: #cbcbcb;
  font-size: 14px;
  font-family: sans-serif;
}

.pt .prm .nav-tabs .nav-link{
margin: 0px;
padding-left: 15px !important;
background: #fff;
}
.pt .prm .nav-tabs .nav-link.active{
margin: 0px;
padding-left: 15px !important;
background: #fde1bd;
border-bottom: 5px solid #ff983d;
}

.jnbt{
  margin-bottom: 30px;
}

.contecttest h4{
  font-size: 14px;
  /* margin-bottom: 12px; */
  color: #000000;
}
table thead th {
color: #000000;
}
.contecttest h2{
  font-size: 20px;
  margin-bottom: 12px;
}


.myLabelComponentInSvg {
  height: 70px;
  width: 180px;
  margin: 10px;
}

 /*graph  */

 .p-organizationchart .p-organizationchart-line-down {
  background: #dee2e6;
}
.p-organizationchart-line-down {
  margin: 0 auto;
  height: 20px;
  width: 1px;
}

.p-organizationchart-table>tbody>tr>td {
  text-align: center;
  vertical-align: top ;
  padding: 2px 5px !important;
  /* padding: 0 0.75rem; */
}
.p-organizationchart-table {
  border-spacing: 0;
  border-collapse: separate;
  margin: 0 auto;
}
.p-component {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: normal;
}
.p-organizationchart-table>tbody>tr>td {
  text-align: center;
  vertical-align: top;
  /* padding: 0 0.75rem; */
}
.organizationchart-demo .card {
  overflow-x: auto;
}

.organizationchart-demo .p-organizationchart .p-person {
  padding: 0;
  border: 0 none;
}

.organizationchart-demo .p-organizationchart .node-header, .organizationchart-demo .p-organizationchart .node-content {
  padding: .5em .7rem;
}

.topheadBox img{
  width: 100%;
}

.organizationchart-demo .p-organizationchart .node-header {
  background-color: #495ebb;
  color: #ffffff;
}

.organizationchart-demo .p-organizationchart .node-content {
  text-align: center;
  border: 1px solid #495ebb;
}

.organizationchart-demo .p-organizationchart .node-content img {
  border-radius: 50%;
}

.organizationchart-demo .p-organizationchart .department-cfo {
  background-color: #7247bc;
  color: #ffffff;
}

.organizationchart-demo .p-organizationchart .department-coo {
  background-color: #a534b6;
  color: #ffffff;
}

.organizationchart-demo .p-organizationchart .department-cto {
  background-color: #e9286f;
  color: #ffffff;
}

@media only screen and (max-width: 575px) {
  
.card1 {
  font-family: sans-serif;
  padding: 1rem;
  max-width: 22rem;
  width: 100%;
  height: 7rem;

  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card1 {
  border-left: 20px solid rgb(24, 130, 178);
  border-radius: 30px;

}

.card2 {
  font-family: sans-serif;
  padding: 1rem;
  max-width: 22rem;
  width: 100%;
  height: 7rem;

  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card2 {
  border-left: 20px solid rgb(69, 178, 150);
  border-radius: 30px;

}

.card3 {
  font-family: sans-serif;
  padding: 1rem;
  max-width: 22rem;
  width: 100%;
  height: 7rem;

  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card3 {
  border-left: 20px solid rgb(250, 149, 50);
  border-radius: 30px;

}

.butt {
  padding: 0px 10px;
  margin-right: 10px;
  border: 1px solid #afadad;
  border-radius: 15px;
  font-size: 10px;
  cursor: pointer;
  color: #afadad !important;
  margin-left: 55px;
}



.card4 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
   height: 110px;
  float: left;
  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card4 {
  border-left: 20px solid rgb(184, 135, 229);
  border-radius: 30px;

}

.buuton {
  margin-left: 800px;
  border-color: blue;
  background-color: white;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;

}


.card5 {
  font-family: sans-serif;
  padding: 1rem;
  width: 100%;
  height: 110px;
  float: left;
  margin: 0.5rem;
  background: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
  transition: all .2s ease;
  border-bottom: 2px solid transparent;
}

.card5 {
  border-left: 20px solid green;
  border-radius: 30px;

}

.img1 {
  position: absolute;
  width: 40px;
  margin-left: 80px;
  margin-top: -50px;
}

.i1 {
  margin-left: 190px;
}


.img2 {
  position: absolute;
  width: 40px;
  margin-left: 55px;
  margin-top: -50px;
}

.img3 {
  position: absolute;
  width: 40px;
  margin-left: 175px;
  margin-top: -50px;
}


}


 


 
 
.register {
	background: linear-gradient(to right, rgba(57,49,175,1) 0, rgba(0,198,255,1) 100%);
	margin-top: 3%;
	padding: 3%;
	overflow: hidden;
}
.register .register-form {
	padding: 10%;
	margin-top: 10%;
}
@media (max-width: 991px){
	.register .register-form {
		margin-top: 16%;
	}
}
@media (max-width: 667px){
	.register .register-form {
		margin-top: 20%;
	}
  .dtpick .form-control {
    /* background-color: #f4f5f9; */
    /* border: 2px solid #5f5f61; */

    padding-left: 3px !important;
}
}
.register .nav-tabs {
	margin-top: 3%;
	border: none;
	background: #0062cc;
	border-radius: 1.5rem;
	width: 28%;
	float: right;
}
@media (max-width: 991px){
	.register .nav-tabs {
		width: 33%;
		margin-top: 8%;
	}
}
.register .nav-tabs .nav-link {
	padding: 2%;
	height: 34px;
	font-weight: 600;
	color: #fff;
	border-top-right-radius: 1.5rem;
	border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover {
	border: none;
}
.register .nav-tabs .nav-link.active {
	width: 100px;
	color: #0062cc;
	border: 2px solid #0062cc;
	border-top-left-radius: 1.5rem;
	border-bottom-left-radius: 1.5rem;
}
.register-left {
	text-align: center;
	color: #fff;
	margin-top: 4%;
}
.register-left input {
	border: none;
	border-radius: 1.5rem;
	padding: 2%;
	width: 60%;
	background: #f8f9fa;
	font-weight: bold;
	color: #383d41;
	margin-top: 30%;
	margin-bottom: 3%;
	cursor: pointer;
}
.register-left img {
	margin-top: 15%;
	margin-bottom: 5%;
	width: 25%;
	animation: mover 1s infinite alternate;
}
.register-left p {
	font-weight: lighter;
	padding: 12%;
	margin-top: -9%;
}
.register-right {
	background: #f8f9fa;
	border-top-left-radius: 10% 50%;
	border-bottom-left-radius: 10% 50%;
}
@-webkit-keyframes mover {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-20px);
	}
}
@keyframes mover {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-20px);
	}
}
.btnRegister {
	float: right;
	margin-top: 10%;
	border: none;
	border-radius: 1.5rem;
	padding: 2%;
	background: #0062cc;
	color: #fff;
	font-weight: 600;
	width: 50%;
	cursor: pointer;
}
.register-heading {
	text-align: center;
	margin-top: 8%;
	margin-bottom: -15%;
	color: #495057;
}
@media (max-width: 991px){
	.register-heading {
		font-size: 1.5rem;
	}
}
.loginbtnr{
  
    border: none;
    border-radius: 1.5rem;
    padding: 15px 30px;
    width: 60%;
    background: #f8f9fa;
    font-weight: bold;
    color: #383d41;
    margin-top: 30%;
    margin-bottom: 3%;
    cursor: pointer;
 
}
.btn.btn-sm, .button.btn-sm {
  padding: 8px 10px !important;
  font-size: 12px;
  text-transform: none;
  line-height: 0.8;
}

.quantity-btn {
  padding: 4px 10px;
  border: none;
  background-color: #dcdcdc;
  color: rgb(0, 0, 0);
  border-radius: 3px;
  cursor: pointer;
  font-size: 18px;

}

.quantity-btn:hover {
  background-color: #3b3b3b;
  color: #ffffff;
}

.quantity {
  width: 50px;
  text-align: center;
  padding: 4px 12px;
  border: 1px solid #ccc;
}

.kitfooter .fa {
  display: inline-block;
  font: normal normal normal 25px / 1 FontAwesome !important;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cropContainer{
  position: relative;
  width: 100%;
  height: 200px;
  background: #333;
   
}
.cropButton {
  flexShrink: 0;
  marginLeft: 16;
}

.shopping-summery{
  overflow: auto;
}
.ldbox:hover .profile_ulv{
  background: #0062cc;
  color: #ffffff;
  cursor: pointer;
}
.ldbox:hover .profile_ulv h6{
  background: #0062cc;
  color: #ffffff;
}

.dyn.menu-item-has-children .dropdown a {
  /* background: #A9A9A9; */
  padding: 10px 5px;
}

.hederctac1 {
  display: block !important;
  padding: 10px 14px;
  /* background: #ff1658; */
  border-radius: 16px;
  /* color: #ffffff !important; */
}
.hederctac1:focus {
  display: block !important;
  padding: 10px 14px;
  background: #ff1658;
  border-radius: 16px;
  color: #ffffff !important;
  box-shadow: 4px 1px 3.9px 1px #ff1658;
}
.hederctac1:active {
  display: block !important;
  padding: 10px 14px;
  background: #ff1658;
  border-radius: 16px;
  color: #ffffff !important;
  box-shadow: 4px 1px 3.9px 1px #ff1658;
}
.hederctac {
  display: block !important;
  padding: 10px 14px;
  margin: 0px 5px;
  background: #ff1658;
  border-radius: 16px;
  color: #ffffff !important;
  box-shadow: 4px 1px 3.9px 1px #ff1658;
}
.dyn.menu-item-has-children .rdm a {
  /* background: #e2e2e2; */
  padding: 10px 5px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li {
  padding: 0px 0;
  border-bottom: none;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul {
  padding: 0px 0 0 10px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover > a {
  color: #0D6EFD;
  display: block;
}



.bg-coverwel {
  /* background-image: url('https://source.unsplash.com/random/1600x900'); */
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 50px;
  /* display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white; */
}
.bg-coverwel1 {
  /* background-image: url('https://source.unsplash.com/random/1600x900');
  background-size: cover;
  background-position: center;
  height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}
.kycfrom{
  padding: 15px;
}
.kycfrom .form-control {
  border: 1px solid #929292;
  border-radius: 1px;
  height: 34px !important;
  padding-left: 18px;
  font-size: 16px;
  background: #ffffff;
  padding: 7px !important;
  border-radius: 5px;
}
  /* .containerm{
    margin-left: 15;
    margin-right: 15;
  } */
.welcome-messagewel {
  background: rgba(0, 0, 0, 0.6);
  padding: 30px;
  border-radius: 10px;
}

.btn-customwel {
  background-color: #ff5e62;
  border-color: #ff5e62;
  color: white;
}

.btn-customwel:hover {
  background-color: #ff3d47;
  border-color: #ff3d47;
}

.bg-coverwel h5{
  color: #ffffff;
}
.bg-coverwel h6{
  color: #ffffff;
}